import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"

import NewsletterDownload from "../../components/NewsletterSignup"
import ContactButton from "../../components/IndexContactBtn"
import headerImg from "../../assets/images/Waterfall_Andreatta_Waterscapes_Grants_Pass_Oregon.jpg"

const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 30%;
  color: white;
  padding: 12rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 20%;
  }
  @media only screen and (max-width: 600px) {
    backgroundposition: 50% 20%;
    background-attachment: scroll;
  }
`
const useStyles = makeStyles(theme => ({
  outerCtr: {
    padding: "6rem 10rem",
    [theme.breakpoints.down("lg")]: {
      padding: "6rem 4rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "6rem 0rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "6rem 1rem",
    },
  },
  h1: {
    fontSize: "2.75rem",
    fontWeight: 600,
    padding: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: "1.5rem",
    paddingBottom: ".75rem",
  },
  body: {
    marginTop: "1rem",
  },
  infoCtr: {
    padding: "2rem",
  },
}))

export default function NewInstallationsPage() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  let SubPage = ({ reverse = false, img, title, description, to = "#" }) => (
    <Grid item xs={12} style={{ paddingBottom: "4rem" }}>
      <Paper elevation={10}>
        <Grid
          container
          direction={
            matchesXS ? "column-reverse" : reverse ? "row-reverse" : undefined
          }
        >
          <Grid item xs={12} sm={7}>
            <Grid container justifyContent="center" className={classes.infoCtr}>
              <Grid item xs={12}>
                <Typography variant="h3" className={classes.h3}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.body}>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Corrupti tempore impedit esse ullam nemo culpa autem libero
                  dignissimos, illum beatae! Sequi accusamus ab distinctio
                  tenetur amet.
                  <br />
                  Sunt maiores nisi modi quas nesciunt ducimus quaerat cum
                  consequatur consequuntur? Dolores, ipsam nesciunt sint
                  repudiandae quam illo, molestiae ipsum, soluta quisquam enim
                  aliquid?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "2rem 0rem" }} />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent={matchesXS ? "center" : undefined}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to={to}
                >
                  Read more
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            {img}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )

  return (
    <Layout>
      <Navbar />
      <Seo
        title="New Installations"
        description="Learn more about the various installation services that Andreatta Waterscapes provides, including ponds, waterfalls, hardscapes & more."
      />
      <Background>
        <Typography variant="h1" className={classes.h1} align="center">
          NEW INSTALLATIONS
        </Typography>
      </Background>
      <Grid container className={classes.outerCtr}>
        <SubPage
          to="/new-installations/goldfish-ponds/"
          title="Goldfish Ponds & Water Gardens"
          img={
            <StaticImage
              src="../../assets/images/10-Andreatta-Waterscapes-Natural-Pond-Central-Point-Oregon.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 0px 160px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Natural Pond Central Point Oregon"
            />
          }
        />
        <SubPage
          to="/new-installations/koi-ponds/"
          title="Koi Ponds"
          reverse={true}
          img={
            <StaticImage
              src="../../assets/images/05-Andreatta-Waterscapes-Koi-Pond-Central-Point-Oregon.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 160px 0px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Koi Pond Central Point Oregon"
            />
          }
        />
        <SubPage
          to="/new-installations/pondless-waterfalls/"
          title="Pondless Waterfalls"
          img={
            <StaticImage
              src="../../assets/images/10-Landscaped-Pondless-Waterfall-Medford-oregon.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 0px 160px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Pondless Waterfall Medford Oregon"
            />
          }
        />
        <SubPage
          to="/new-installations/waterfalls-streams/"
          title="Waterfalls & Streams"
          reverse={true}
          img={
            <StaticImage
              src="../../assets/images/01-Andreatta-Waterscapes-Backyard-Waterfall-Central-Point-Oregon.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 160px 0px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Backyard Waterfall Central Point Oregon"
            />
          }
        />
        <SubPage
          to="/new-installations/fountainscapes/"
          title="Fountainscapes & Spillways"
          img={
            <StaticImage
              src="../../assets/images/backyard_stream_spillway.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 0px 160px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Backyard Stream built by Andreatta Waterscapes"
            />
          }
        />
        <SubPage
          to="/new-installations/recreation-ponds/"
          title="Recreation Ponds"
          reverse={true}
          img={
            <StaticImage
              src="../../assets/images/recreationalpond.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 160px 0px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Recreational Pond Jacksonville Oregon"
            />
          }
        />
        <SubPage
          to="/new-installations/bogs-wetland-filters/"
          title="Bogs & Wetland Filters"
          img={
            <StaticImage
              src="../../assets/images/10-Andreatta-Waterscapes-Natural-Pond-Central-Point-Oregon.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 0px 160px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Pondless Waterfall Medford Oregon"
            />
          }
        />
        <SubPage
          to="/new-installations/fire-suppression-ponds/"
          title="Farm & Fire Suppression Ponds"
          reverse={true}
          img={
            <StaticImage
              src="../../assets/images/12-Andreatta-Waterscapes-Fish-Pond-Central-Point-Oregon.jpg"
              placeholder="blurred"
              imgStyle={{
                borderRadius: "0px 0px 160px 0px",
              }}
              style={{
                height: "100%",
                marginLeft: "auto",
                display: "flex",
              }}
              height={350}
              quality={90}
              alt="Pondless Waterfall Medford Oregon"
            />
          }
        />
        <NewsletterDownload />
        <ContactButton />
      </Grid>
    </Layout>
  )
}
