import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

const styles = theme => ({
  root: {
    // marginTop: theme.spacing(10),
    // marginBottom: theme.spacing(6),
    display: "flex",
  },
  cardMargin: {
    marginTop: classes => classes.marginTop,
    marginBottom: classes => classes.marginBottom,
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    marginTop: "3rem",
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "#5BC8E2",
    backgroundColor: theme.palette.common.orange,
    padding: theme.spacing(6, 2.5),
    maxWidth: "75%",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "65%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: "100%",
    borderRadius: 0,
    backgroundColor: "#1a1a1b",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    "& .MuiInputBase-root": {
      backgroundColor: "#1a1a1b",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: ".75rem .875rem",
    },
  },
  button: {
    width: "100%",
    height: "2.725rem",
    color: "#dddddd",
    borderRadius: 0,
    backgroundColor: '#1a1a1b',
    "&:hover": {
      // backgroundColor: "rgba(0,0,0,.9)",
      backgroundColor: "#1C1C1E",
    },
  },
  imagesWrapper: {
    position: "relative",
  },
  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: 462,
    maxHeight: 345,
  },
  h2: {
    // fontSize: "2.875rem",
    fontFamily: "Work Sans, sans-serif",
    fontSize: "1.75rem",
    // fontWeight: 600,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  h5: {
    fontSize: "1.0515rem",
    // color: theme.palette.common.muted,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
})

function NewsletterSignup(props) {
  const { classes } = props

  return (
    <Container
      className={`${classes.root} ${classes.cardMargin}`}
      component="section"
    >
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form
              className={classes.cardContent}
              name="Newsletter Signup"
              method="POST"
              // netlify
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="Newsletter Signup" />
              <Typography
                variant="h2"
                component="h2"
                className={classes.h2}
                gutterBottom
              >
                HOW TO MAINTAIN YOUR BACKYARD POND
              </Typography>
              <Typography variant="h5" className={classes.h5}>
                Register for our newsletter to receive your free copy
              </Typography>
              <TextField
                className={classes.textField}
                placeholder="Your email"
                variant="outlined"
                id="Email Address"
                name="Email Address"
              />
              <Button
                type="submit"
                color="inherit"
                variant="contained"
                disableElevation={true}
                className={classes.button}
              >
                Get it!
              </Button>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <StaticImage
              src="../assets/images/12-Andreatta-Waterscapes-Fish-Pond-Central-Point-Oregon.jpg"
              // layout="fixed"
              // height={400}
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  )
}

NewsletterSignup.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NewsletterSignup)
